import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "years",
})
export class YearsPipe implements PipeTransform {
  transform(date: Date | string): number {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    const today = new Date();
    const m = today.getMonth() - date.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      // if it is before the month or same month but before the day, subtract 1 year
      return today.getFullYear() - date.getFullYear() - 1;
    }

    return today.getFullYear() - date.getFullYear();
  }
}
