export enum CommunicationType {
  Phone = 1,
  Email = 2,
  Text = 3,
  GoogleChat = 4,
  Tutoring = 5,
  SSW = 6,
  IEPServiceTime = 7,
  GoogleMeet = 8,
  InPerson = 9,
}
